import NewsletterCtaRow from 'components/newsletter-cta-row'
import SearchArticles from 'components/search-articles'
import { graphql, Link } from 'gatsby'
import React, { Component } from 'react'
import Helmet from 'react-helmet'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import Layout from '../layouts'

const slickSettings = {
    centerMode: true,
    centerPadding: '32px',
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 20000,
    draggable: true,
    variableWidth: true,
    dots: false,
    arrows: true,
    touchMove: false,
    infinite: true,
    responsive: [
        {
            breakpoint: 1120,
            settings: {
                touchMove: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
            },
        },
        {
            breakpoint: 768,
            settings: {
                centerPadding: '24px',
                touchMove: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
            },
        },
    ],
}

const fallback = {
    edges: [],
    categories: [],
}

class KennisbankCategorie extends Component {
    constructor(props) {
        super(props)

        this.checkIfEven = this.checkIfEven.bind(this)
        this.readMore = this.readMore.bind(this)
    }

    checkIfEven(num) {
        const even = 'col-4 flex'
        const odd = 'col-4 flex odd'

        if (num % 2 === 0) {
            return even
        } else {
            return odd
        }
    }

    readMore() {
        const articles = this.refs.articles
        const more = this.refs.more
        articles.classList.add('show')

        if (more) {
            more.classList.add('hide')
        }
    }

    render() {
        const { pageContext } = this.props
        const { edges } = this.props.data.allContentfulArticle || fallback
        const { edges: categories } =
            this.props.data.allContentfulCategory || fallback

        return (
            <Layout location={this.props.location}>
                <main className="kennisbank categories animated">
                    <Helmet>
                        <title>{pageContext.metaTitle}</title>
                        <meta
                            name="description"
                            content={pageContext.metaDescription}
                        />
                        <meta itemprop="name" content={pageContext.metaTitle} />
                        <meta
                            itemprop="description"
                            content={pageContext.metaDescription}
                        />
                    </Helmet>

                    <header className="padding-xl">
                        <div className="container-md">
                            <div className="grid yg center text-center align-middle">
                                <div className="col-12 header-text">
                                    <h2 className="eyebrow">Hulp</h2>
                                    <h1>Blog</h1>

                                    <p className="streamer center margin-m-bottom">
                                        Bekijk de laatste trends, tips en
                                        inzichten om jouw HR &
                                        salarisadministratie tot in de puntjes
                                        goed te regelen.
                                    </p>

                                    <SearchArticles />

                                    <ul
                                        className="categories margin-m-top"
                                        id="categories"
                                    >
                                        <li
                                            data-target="Alle artikelen"
                                            className="category-item margin-xxs-left margin-xs-right"
                                        >
                                            <Link
                                                to="/blog/"
                                                title="Kennisbank"
                                            >
                                                <h6>
                                                    Alle
                                                    <span className="hide-mobile">
                                                        &nbsp;artikelen
                                                    </span>
                                                </h6>
                                            </Link>
                                        </li>

                                        {categories.map((category, idx) => (
                                            <Link
                                                to={
                                                    '/blog/' +
                                                    category.node.slug +
                                                    '/'
                                                }
                                                title={category.node.title}
                                            >
                                                <li
                                                    data-target=""
                                                    className={
                                                        'category-item margin-xxs-left margin-xs-right ' +
                                                        (pageContext.slug ===
                                                        category.node.slug
                                                            ? 'active'
                                                            : '')
                                                    }
                                                    key={idx}
                                                >
                                                    <h6>
                                                        {category.node.title}
                                                    </h6>
                                                </li>
                                            </Link>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>

                    <section className="articles  padding-xxl-bottom">
                        <div className="backdrop"></div>

                        <div className="container-md">
                            <div
                                className="grid yg article-overview"
                                ref="articles"
                            >
                                {edges.map((article, idx) => (
                                    <div
                                        className={this.checkIfEven(idx)}
                                        key={idx}
                                    >
                                        <div className="articles-card flex">
                                            <Link
                                                to={`/${article.node.slug}/`}
                                                key={idx}
                                            >
                                                <img
                                                    src={
                                                        article.node.heroImage
                                                            .file.url
                                                    }
                                                />
                                                <div className="articles-card-content">
                                                    <h5
                                                        className="streamer"
                                                        dangerouslySetInnerHTML={{
                                                            __html: article.node
                                                                .title,
                                                        }}
                                                    ></h5>
                                                    <h2 className="eyebrow">
                                                        {pageContext.slug}
                                                    </h2>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                ))}

                                {edges.length > 4 ? (
                                    <div className="more" ref="more">
                                        <div
                                            className="btn primary"
                                            onClick={this.readMore}
                                        >
                                            Bekijk meer artikelen
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </section>

                    <div className="padding-xl-bottom">
                        <NewsletterCtaRow />
                    </div>
                    {/*
        <section className="vision padding-xxl">
          <div className="container-sm">
            <div className="grid yg">
              <div className="col-12">
                <p className="eyebrow">Visie</p>
                <h3>Wij helpen je graag verder</h3>
              </div>
              <div className="col-6">
                <p className="margin-s-bottom">
                  For many of us, our very first experience of learning about the celestial bodies begins when we saw our first full moon in the sky. It is truly a magnificent view even to the naked eye. If the night is clear, you can see amazing detail of the lunar surface just star gazing on in your back yard.
                </p>

                <p className="margin-s-bottom">
                  Naturally, as you grow in your love of astronomy, you will find many celestial bodies fascinating. But the moon may always be our first love because is the one far away space object that has the unique distinction of flying close to the earth and upon which man has walked.
                </p>

                <p>
                  Your study of the moon, like anything else, can go from the simple to the very complex. To gaze at the moon with the naked eye, making yourself familiar with the lunar map will help you pick out the seas, craters and other geographic phenomenon that others have already mapped to make your study more enjoyable. Moon maps can be had.
                </p>
              </div>
              <div className="col-6">
                <p className="margin-s-bottom">
                  The best time to view the moon, obviously, is at night when there are few clouds and the weather is accommodating for a long and lasting study. The first quarter yields the greatest detail of study. And don’t be fooled but the blotting out of part of the moon when it is not in full moon stage. The phenomenon known as “earthshine” gives you the ability to see the darkened part of the moon with some detail as well, even if the moon is only at quarter or half display.
                </p>

                <p>
                  To kick it up a notch, a good pair of binoculars can do wonders for the detail you will see on the lunar surface. For best results, get a good wide field in the binocular settings so you can take in the lunar landscape in all its beauty. And because it is almost impossible to hold the binoculars still for the length of time you will want to gaze at this magnificent body in space, you may want to add to your equipment arsenal a good tripod that you can affix.
                </p>
              </div>
            </div>
          </div>
        </section>
          */}
                </main>
            </Layout>
        )
    }
}

export default KennisbankCategorie

export const categoryQuery = graphql`
    query rightCategory($slug: String!) {
        allContentfulCategory(
            limit: 100
            filter: { node_locale: { eq: "nl" } }
            sort: { fields: [order], order: ASC }
        ) {
            edges {
                node {
                    slug
                    title
                    order
                }
            }
        }
        allContentfulArticle(
            filter: {
                node_locale: { eq: "nl" }
                hideInBlog: { eq: false }
                category: { slug: { eq: $slug } }
            }
            sort: { fields: [published], order: DESC }
        ) {
            edges {
                node {
                    id
                    title
                    slug
                    createdAt
                    heroImage {
                        file {
                            url
                        }
                    }
                    category {
                        id
                        slug
                    }
                }
            }
        }
    }
`
